<template>
  <b-card title="Notificaciones de turnos">
    <b-row v-if="notifications.length">
      <b-col md="12">
        <DxDataGrid
          :data-source="notifications"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :columnAutoWidth="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          id="settlementTable"
        >
          <DxColumnChooser
            emptyPanelText="Arrastre una columna aquí para ocultar"
            :enabled="true"
            title="Arrastre columna aquí..."
          />
          <DxColumnFixing :enabled="true" />
          <DxGroupPanel
            :visible="true"
            emptyPanelText="Arrastre una columna aquí para agrupar"
          />
          <DxSearchPanel :visible="true" :width="240" placeholder="Buscar..." />
          <DxPaging :page-size="30" />
          <DxPager
            :visible="true"
            infoText="Página {0} de {1} ({2} registros)"
            :allowed-page-sizes="[5, 15, 30, 50]"
            display-mode="full"
            :show-page-size-selector="true"
            :show-info="true"
            :show-navigation-buttons="true"
          />
          <DxGroupPanel :visible="true" />
          <DxColumn 
            caption="Codigo" 
            alignment="center"
            data-field="codigo" 
            
          />
          <DxColumn
            caption="Placa"
            alignment="center"
            data-field="placa"
           
          />
          <DxColumn
            caption="Fecha de inicio"
            alignment="center"
            data-field="fecha"
          />
          <DxColumn
            caption="Fecha de fin"
            alignment="center"
            data-field="fecha_fin"

          />
          <DxColumn
            caption="Estado"
            alignment="center"
            data-field="estado.estado"

          /> 
          <DxColumn
            caption="Contrata"
            alignment="center"
            data-field="contrata"

          /> 
          <DxColumn
            caption="Brigada"
            alignment="center"
            data-field="brigada.nombre"

          />
          <DxColumn
              caption="Gestionar"
              width="150"
              alignment="center"
              cell-template="cellTemplate"
          />
          <template #cellTemplate="{ data: cellData }">
            <div class="text-center">
              <router-link
              :to="`turns/authorization/${cellData.data.id}`"
                class="btn btn-sm btn-circle action"
              >
                <i class="flaticon-folder-1 text-primary"></i>
              </router-link>
            </div>
          </template>
          <template #refreshTemplate>
            <DxButton icon="refresh" @click="loadNotifications()" />
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="d-flex justify-content-center flex-colunm" v-if="!isLoading">
          <div class="text-center">
            <img src="assets/img/empty.png" alt="Empty" />
            <p class="text-muted">No hay información para mostrar</p>
          </div>
        </div>
        <div class="text-center" v-else>
          <img src="assets/img/loader.gif" alt="Loading..." />
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import can from "@/permission";
import {
  DxDataGrid,
  DxPaging,
  DxSearchPanel,
  DxPager,
  DxGroupPanel,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,

} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";

export default {
  components: {
    DxDataGrid,
    DxPaging,
    DxSearchPanel,
    DxPager,
    DxGroupPanel,
    DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxButton,
  },
  data(){
    return {
      notifications: [],
      isLoading: false,
    }
  },
  mounted() {
    if (this.can("notifications")) {
      this.loadNotifications();

      setInterval(() => {
        this.loadNotifications();
      }, 60 * 1000);
    }
  },
  methods: {
    can: can,
    loadNotifications() {
      this.isLoading = true;
      axios
        .get(`/turns-notifications`)
        .then((res) => {
          this.notifications = res.data;
          this.notifications.map((el) => {
            el.text = `Autorización para turno ${el.observacion}`;
            el.from = moment(el.updated_at).fromNow();
            el.fecha = moment(el.fecha).format('YYYY-MM-DD');
            el.fecha_fin = moment(el.fecha_fin).format('YYYY-MM-DD')
          });
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
        });
    },
    deleteNotifications(id) {
      this.$swal
        .fire({
          title: "Información",
          text: "¿Quiere eliminar esta notificacion?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Eliminar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .delete(`/turns-notifications/${id}`)
              .then((res) => {
                if (res.data.message == "success") {
                  this.$swal.fire(
                    "Información!",
                    res.data.information,
                    "success"
                  );
                  this.loadNotifications();
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        });
    },
  }
};
</script>
